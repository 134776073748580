<template>
    <div>
        <VueApexCharts
            height="390"
            type="radialBar"
            :series="getSeries"
            :options="getChartOptions"
        />
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default ({
    components: {
        VueApexCharts
    },
    props: {
        data: {
            type: Array
        }
    },
    data() {
        return {}
    },
    computed: {
        getSeries() {
            return this.data.map(e => parseInt(e.scale)*10)
        },
        getChartOptions() {
            return {
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '30%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                show: false
                            },
                        },
                        barLabels: {
                            enabled: true,
                            useSeriesColors: true,
                            offsetX: -8,
                            fontSize: '11px',
                            formatter: function(seriesName, opts) {
                                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                            },
                        }
                    }
                },
                colors: ['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'],
                labels: this.data.map(e => e.item),
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: false
                        }
                    }
                }]
            }
        }
    }
})
</script>