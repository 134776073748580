var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"w":"100%","px":"10px","py":"12px","mb":"12px","bg":"buttonSecondary","borderRadius":"10px"}},[_c('CBox',{attrs:{"mb":"1","d":"flex","justifyContent":"space-between"}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"700","color":"fontMain","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
          name: 'Profile',
          params: {
            emp_id: _vm.sender_id,
          },
        })}}},[_vm._v(_vm._s(_vm.current_user.emp_id == _vm.sender_id ? "You" : _vm.sender))]),_c('CMenu',[_c('CMenuButton',{attrs:{"w":"fit-content","h":"fit-content","bg":"transparent","_hover":{ bg: 'transparent' },"_focus":{ outline: 0, boxShadow: 'none', border: 0 },"_active":{ bg: 'transparent' }}},[_c('ButtonIcon',{attrs:{"icon":"ic-menu-solid.svg"}})],1),_c('CMenuList',[(_vm.isUserComment())?_c('CMenuItem',{on:{"click":_vm.getSelectedComment}},[_vm._v("Edit")]):_vm._e(),(_vm.isUserComment())?_c('CMenuItem',{on:{"click":_vm.removeComment}},[_vm._v("Delete")]):_vm._e(),(!_vm.isUserComment())?_c('CMenuItem',{on:{"click":function($event){_vm.$router.push({
              name: 'Report Comment',
              params: { job_id: _vm.result_id, comment_id: _vm.id.toString() },
            })}}},[_vm._v("Report")]):_vm._e()],1)],1)],1),_c('CBox',{attrs:{"mb":"2"}},[_c('CText',{attrs:{"id":_vm.bodyElementId,"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"500","color":"fontMain"}})],1),_c('CGrid',{attrs:{"w":"100%","templateColumns":"4fr 8fr","gap":"2"}},[_c('CBox',{attrs:{"w":"fit-content","d":"flex","cursor":"pointer","alignItems":"center"},on:{"click":_vm.postCommentLike}},[_c('ButtonIcon',{attrs:{"icon":_vm.isLikedByUser ? 'ic-heart-solid.svg' : 'ic-heart-regular.svg',"btnWidth":"14px","btnHeight":"14px"}}),_c('CText',{attrs:{"ml":"1","fontFamily":"Montserrat","fontStyle":"normal","fontSize":"10px","fontWeight":"600","color":"main"}},[_vm._v(_vm._s(`${_vm.likes.length} ${_vm.likes.length > 1 ? "likes" : "like"}`))])],1),_c('CBox',{attrs:{"d":"flex","justifyContent":"flex-end","alignItems":"center"}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"12px","fontWeight":"500","color":"breadcrumbActive"}},[_vm._v(_vm._s(_vm.formatCommentDate(_vm.created_at)))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }