import { render, staticRenderFns } from "./ModalComments.vue?vue&type=template&id=c70f761c"
import script from "./ModalComments.vue?vue&type=script&lang=js"
export * from "./ModalComments.vue?vue&type=script&lang=js"
import style0 from "./ModalComments.vue?vue&type=style&index=0&id=c70f761c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CDivider: require('@chakra-ui/vue').CDivider, CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CInput: require('@chakra-ui/vue').CInput, CButton: require('@chakra-ui/vue').CButton, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
