<template>
    <div>
        <VueApexCharts 
            type="radar"
            :height="height"
            :options="getChartOptions"
            :series="getSeries"
        />
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    components: {
        VueApexCharts
    },
    props: {
        height: {
            type: Number,
            default: 350
        },
        title: {
            type: String
        },
        data: {
            type: Array,
        }
    },
    data() {
        return {}
    },
    computed: {
        getSeries() {
            return [{
                name: "Point",
                data: this.data.map(e => parseInt(e.scale)*10)
            }]
        },
        getChartOptions() {
            return {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                yaxis: {
                    stepSize: Math.max(...this.data.map(e => parseInt(e.scale)))
                },
                xaxis: {
                    categories: this.data.map(e => e.item.split(" ")[0] + "...")
                },
            }
        }
    },
    methods: {
        shortText(text, maxLength) {
            if (text.length > maxLength) {
                return text.slice(0, maxLength) + "..."
            }
            return text
        }
    }
}
</script>