<template>
  <CBox
    w="100%"
    px="10px"
    py="12px"
    mb="12px"
    bg="buttonSecondary"
    borderRadius="10px"
  >
    <CBox mb="1" d="flex" justifyContent="space-between">
      <CText
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="14px"
        fontWeight="700"
        color="fontMain"
        :_hover="{ opacity: '0.8' }"
        cursor="pointer"
        @click.native="
          $router.push({
            name: 'Profile',
            params: {
              emp_id: sender_id,
            },
          })
        "
        >{{ current_user.emp_id == sender_id ? "You" : sender }}</CText
      >
      <CMenu>
        <CMenuButton
          w="fit-content"
          h="fit-content"
          bg="transparent"
          :_hover="{ bg: 'transparent' }"
          :_focus="{ outline: 0, boxShadow: 'none', border: 0 }"
          :_active="{ bg: 'transparent' }"
        >
          <ButtonIcon icon="ic-menu-solid.svg" />
        </CMenuButton>
        <CMenuList>
          <CMenuItem v-if="isUserComment()" @click="getSelectedComment"
            >Edit</CMenuItem
          >
          <CMenuItem v-if="isUserComment()" @click="removeComment"
            >Delete</CMenuItem
          >
          <CMenuItem
            v-if="!isUserComment()"
            @click="
              $router.push({
                name: 'Report Comment',
                params: { job_id: result_id, comment_id: id.toString() },
              })
            "
            >Report</CMenuItem
          >
        </CMenuList>
      </CMenu>
    </CBox>
    <CBox mb="2">
      <CText
        :id="bodyElementId"
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="14px"
        fontWeight="500"
        color="fontMain"
      />
    </CBox>
    <CGrid w="100%" templateColumns="4fr 8fr" gap="2">
      <CBox
        w="fit-content"
        d="flex"
        cursor="pointer"
        alignItems="center"
        @click="postCommentLike"
      >
        <ButtonIcon
          :icon="isLikedByUser ? 'ic-heart-solid.svg' : 'ic-heart-regular.svg'"
          btnWidth="14px"
          btnHeight="14px"
        />
        <CText
          ml="1"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="10px"
          fontWeight="600"
          color="main"
          >{{ `${likes.length} ${likes.length > 1 ? "likes" : "like"}` }}</CText
        >
      </CBox>
      <CBox d="flex" justifyContent="flex-end" alignItems="center">
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="12px"
          fontWeight="500"
          color="breadcrumbActive"
          >{{ formatCommentDate(created_at) }}</CText
        >
      </CBox>
    </CGrid>
  </CBox>
</template>

<script>
import moment from "moment-timezone";

import config from "@/ConfigProvider";
import { POST_LIKE, REMOVE_LIKE } from "@/store/like.module";
import {
  GET_COMMENTS,
  REMOVE_COMMENT,
  SET_SELECTED_COMMENT,
} from "@/store/comment.module";

import ButtonIcon from "@/components/button/ButtonIcon.vue";

export default {
  components: {
    ButtonIcon,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    result_id: {
      type: String,
      default: "",
    },
    challenge_slug: {
      type: String,
      default: "",
    },
    sender_id: {
      type: String,
      default: "",
    },
    sender: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
    likes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLikedByUser: {
      type: Boolean,
      default: false,
    },
    current_user: {
      type: Object,
      default: () => {
        return null;
      },
    },
    base_url: {
      type: String,
      default: "",
    },
    created_at: {
      type: String,
      default: "",
    },
    updated_at: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bodyElementId: "",
      linkRegex: /(https?:\/\/[^\s]+)/g,
      hashtagRegex: /#\S+/g,
      defaultTimezone: config.value("DEFAULT_TIMEZONE"),
    };
  },
  watch: {
    body(val) {
      this.parseContentOnBody(val);
    },
  },
  created() {
    this.bodyElementId = `bodyParagraph${this.id}`;
  },
  mounted() {
    this.parseContentOnBody(this.body);
  },
  methods: {
    parseLinkOnBody(sentence) {
      return sentence.replace(this.linkRegex, (link) => {
        return `<a class="content__link" href="${link}" target="_blank">${link}</a>`;
      });
    },
    transformCommentText(sentence) {
      const regex = /([#][\w\d_]+)|([^#]+|\s+)/g;
      let match;
      const result = [];

      while ((match = regex.exec(sentence)) !== null) {
        // The match array contains the matched text and the captured groups
        const hashtagText = match[1];
        const regularText = match[2];

        if (hashtagText) {
          result.push(hashtagText);
        } else if (regularText) {
          result.push(regularText);
        }
      }

      return result.filter((text) => text !== "");
    },
    parseHashtagOnBody(sentence) {
      return sentence.replace(this.hashtagRegex, (hashtag) => {
        return `<a class="content__link" href="${
          window.location.origin
        }/leaderboard/${this.challenge_slug}?hashtag=${hashtag.replace(
          "#",
          ""
        )}"target="_blank">${hashtag}</a>`;
      });
    },
    parseContentOnBody(sentence) {
      const transformedText = this.transformCommentText(sentence);
      const mainElement = document.createElement("span");

      transformedText.forEach((text) => {
        const childEl = document.createElement("span");
        if (text.startsWith("#")) {
          const parsedSentence = this.parseHashtagOnBody(
            this.parseLinkOnBody(text)
          );
          childEl.innerHTML = parsedSentence;
        } else {
          childEl.innerText = text;
        }

        mainElement.append(childEl);
      });
      document.getElementById(this.bodyElementId).append(mainElement);
    },
    redirectToSignIn() {
      window.location.href = this.base_url + "/signin";
    },
    isUserComment() {
      return (
        this.current_user &&
        this.current_user.emp_id === parseInt(this.sender_id)
      );
    },
    formatCommentDate(date) {
      return moment(date).tz(this.defaultTimezone).fromNow();
    },
    async getAllComments() {
      await this.$store.dispatch(GET_COMMENTS, { id: this.result_id });
    },
    getSelectedComment() {
      this.$store.commit(SET_SELECTED_COMMENT, {
        id: this.id,
        body: this.body,
      });
    },
    async removeComment() {
      await this.$store.dispatch(REMOVE_COMMENT, { id: this.id });
      this.getAllComments();
      this.$emit("updateData");
    },
    async postCommentLike() {
      if (this.current_user) {
        try {
          await this.$store.dispatch(POST_LIKE, {
            type: "comment",
            id: this.id,
          });
        } catch (err) {
          if (err.response.data.message === "Already like this content") {
            await this.$store.dispatch(REMOVE_LIKE, {
              type: "comment",
              id: this.id,
            });
          }
        } finally {
          this.getAllComments();
        }
      } else {
        this.redirectToSignIn();
      }
    },
  },
};
</script>

<style>
.content__link {
  color: #29aafe;
}
</style>
