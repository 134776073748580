<template>
    <CBox width="100%">
        <!-- <CBox :d="['none', 'none', 'block', 'block']"> -->
        <CBox class="profession-container">
            <RadialChart v-if="currentAppType.review_job_target === 'Matching'" :data="result.competencies" />
            <RadarChart v-if="currentAppType.review_job_target === 'Profession'" :data="result.competencies" />
            <!-- Heading -->
            <CBox width="100%" bgColor="white" color="#4A4A4A" borderRadius="8px" mb="2rem">
                <CText v-if="type === 'Profession'" font-family="Montserrat" font-size="10px" font-weight="400"
                    line-height="12px" width="100%">
                    Profesi yang cocok untuk kamu
                </CText>
                <CText v-if="type === 'Levelling'" font-family="Montserrat" font-size="10px" font-weight="400"
                    line-height="12px" width="100%">
                    Level kamu
                </CText>
                <CText v-if="type === 'Matching'" font-family="Montserrat" font-size="10px" font-weight="400"
                    line-height="12px" width="100%">
                    Hasil evaluasi yang sesuai untuk kamu
                </CText>
                <CBox v-if="currentResult">
                    <CText font-family="Montserrat" d="inline-flex" alignItems="center" font-size="20px"
                        font-weight="700" lineHeight="40px">
                        {{ currentResult['label'] && type != 'Matching' ? currentResult['label'] : null }}
                        {{ type === "Levelling" || type === "Matching" ? currentAppType['review_role_name'] : null }}
                        <CBox v-if="type === 'Matching'" ml="15px">
                            <CBox v-if="currentResult['label'] === 'Match'" d="flex" bg="passedColor" borderRadius="full" w="100px" h="25px"
                                justifyContent="center" minW="110px">
                                <CBox d="table-cell" verticalAlign="middle" h="inherit">
                                    <CText lineHeight="25px" fontFamily="Montserrat" fontStyle="normal"
                                        fontSize="12px" color="white">{{ currentResult['label'] }}</CText>
                                </CBox>
                            </CBox>
                            <CBox v-else d="flex" bg="failedColor" borderRadius="full" w="100px" h="25px"
                                justifyContent="center" minW="110px">
                                <CBox d="table-cell" verticalAlign="middle" h="inherit">
                                    <CText lineHeight="25px" fontFamily="Montserrat" fontStyle="normal"
                                        fontSize="12px" color="white">{{ currentResult['label'] }}</CText>
                                </CBox>
                            </CBox>
                        </CBox>
                    </CText>

                    <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                        {{ currentResult['reason'] }}
                    </CText>
                </CBox>
                <CBox v-else>
                    <CText font-family="Montserrat" font-size="20px" font-weight="700" lineHeight="40px">
                        -
                    </CText>
                    <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                        -
                    </CText>
                </CBox>
            </CBox>
            <!-- Detail -->
            <CGrid v-if="type === 'Profession'" :d="['block', 'block', 'grid', 'grid']" width="100%" marginTop="1rem"
                templateColumns="1fr 1fr" minHeight="fit-content" height="300px">
                <CBox overflow="auto" height="inherit">
                    <CGrid v-for="(item, index) in result['results']" :key="index" templateColumns="40px 1fr" gap="12px"
                        padding="8px"
                        :bgColor="item['key'] === selected['key'] ? ['#F0FFF2', '#FEF9EC', '#FAEBEB', '#E7F5FB', '#F5EDFF'][index % 5] : 'white'"
                        borderRadius="8px 0px 0px 8px" @click.native="changeSelected(item['key'], [index % 5]);">
                        <CBox :bg="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][index % 5]" d="flex" p="8px"
                            borderRadius="8px">
                            <CImage v-if="['Software Engineer', 'Data Engineer', 'Project Manager'].includes(
                                item['item']
                            )" :src="require('@/assets/icon/icon-' +
                                item['item']
                                    .toLowerCase()
                                    .replace(' ', '-') +
                                '.svg')" :alt="item['item'].toLowerCase()" />
                            <CImage v-else :src="require('@/assets/icon/icon-software-engineer.svg')"
                                :alt="item['item'].toLowerCase()" />
                        </CBox>
                        <CBox d="flex" flexDirection="column">
                            <CText font-family="Montserrat" font-size="12px" font-weight="700" line-height="18px">
                                {{ item["item"] }}
                            </CText>
                            <CGrid templateColumns="1fr 41px" alignItems="center" gap="8px">
                                <CProgress :value="parseInt(item['percentage'].replace('%', ''))"
                                    :color="['green', 'yellow', 'red', 'blue', 'indigo'][index % 5]" size="sm" />
                                <CText font-family="Inter" font-size="16px" font-weight="700" line-height="19.36px">
                                    {{ item["percentage"] }}
                                </CText>
                            </CGrid>
                        </CBox>
                    </CGrid>
                </CBox>
                <CBox :bgColor="['#F0FFF2', '#FEF9EC', '#FAEBEB', '#E7F5FB', '#F5EDFF'][selected['color'] || 0]"
                    :borderRight="'2px solid ' + ['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['color'] || 0]"
                    borderRadius="0px 8px 8px 0px" padding="8px 16px 8px 16px">
                    <CText font-family="Montserrat" font-size="25px" font-weight="700" line-height="30px"
                        :color="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['color'] || 0]">{{
                            selected['percentage'] }}</CText>
                    <CText font-family="Montserrat" font-size="16px" font-weight="700" line-height="26px"
                        :color="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['color'] || 0]">{{
                            selected['item'] }}</CText>
                    <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                        {{ selected['reason'] }}
                    </CText>
                </CBox>
            </CGrid>
            <CGrid v-else-if="type === 'Levelling' || type === 'Matching'" :d="['block', 'block', 'grid', 'grid']"
                width="100%" marginTop="1rem" templateColumns="1fr 1fr" minHeight="fit-content" height="300px">
                <CBox overflow="auto" minHeight="fit-content" height="fit-content">
                    <CGrid v-for="(item, index) in result['competencies']" :key="index" templateColumns="40px 1fr"
                        gap="12px" padding="8px"
                        :bgColor="item['key'] === selected['key'] ? ['#F0FFF2', '#FEF9EC', '#FAEBEB', '#E7F5FB', '#F5EDFF'][index % 5] : 'white'"
                        borderRadius="8px 0px 0px 8px" @click.native="changeSelected(item['key'], [index % 5]);">
                        <CBox :bg="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][index % 5]" d="flex" p="8px"
                            borderRadius="8px">
                            <CImage v-if="['Software Engineer', 'Data Engineer', 'Project Manager'].includes(
                                item['item']
                            )" :src="require('@/assets/icon/icon-' +
                                item['item']
                                    .toLowerCase()
                                    .replace(' ', '-') +
                                '.svg')" :alt="item['item'].toLowerCase()" />
                            <CImage v-else :src="require('@/assets/icon/icon-software-engineer.svg')"
                                :alt="item['item'].toLowerCase()" />
                        </CBox>
                        <CBox d="flex" flexDirection="column">
                            <CText font-family="Montserrat" font-size="12px" font-weight="700" line-height="18px">
                                {{ item["item"] }}
                            </CText>
                            <CGrid templateColumns="1fr 41px" alignItems="center" gap="8px">
                                <CProgress :value="parseInt(item['scale'].replace('%', ''))"
                                    :color="['green', 'yellow', 'red', 'blue', 'indigo'][index % 5]" size="sm" />
                                <CText font-family="Inter" font-size="16px" font-weight="700" line-height="19.36px">
                                    {{ item["scale"] }}
                                </CText>
                            </CGrid>
                        </CBox>
                    </CGrid>
                </CBox>
                <CBox :bgColor="['#F0FFF2', '#FEF9EC', '#FAEBEB', '#E7F5FB', '#F5EDFF'][selected['color'] || 0]"
                    :borderRight="'2px solid ' + ['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['color'] || 0]"
                    borderRadius="0px 8px 8px 0px" padding="8px 16px 8px 16px">
                    <CText font-family="Montserrat" font-size="25px" font-weight="700" line-height="30px"
                        :color="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['color'] || 0]">{{
                            selected['scale'] }}</CText>
                    <CText font-family="Montserrat" font-size="16px" font-weight="700" line-height="26px"
                        :color="['#0CA82B', '#F4C541', '#D13A37', '#1297DB', '#9747FF'][selected['color'] || 0]">{{
                            selected['item'] }}</CText>
                    <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                        {{ selected['reason'] }}
                    </CText>
                </CBox>
            </CGrid>
            <CGrid v-else :d="['block', 'block', 'grid', 'grid']" width="100%" marginTop="1rem"
                templateColumns="1fr 1fr">
                <CGrid templateColumns="1fr">
                    <CGrid templateColumns="40px 1fr" gap="12px" padding="8px" bgColor="#F0FFF2"
                        borderRadius="8px 0px 0px 8px">
                        <CBox bg="#0CA82B" d="flex" p="8px" borderRadius="8px">
                            <CImage :src="require('@/assets/icon/icon-software-engineer.svg')" alt="default-icon" />
                        </CBox>
                        <CBox d="flex" flexDirection="column">
                            <CText font-family="Montserrat" font-size="12px" font-weight="700" line-height="18px">
                                -
                            </CText>
                            <CGrid templateColumns="1fr 41px" alignItems="center" gap="8px">
                                <CProgress value="0" color="green" size="sm" />
                                <CText font-family="Inter" font-size="16px" font-weight="700" line-height="19.36px">
                                    0%
                                </CText>
                            </CGrid>
                        </CBox>
                    </CGrid>
                </CGrid>
                <CBox bgColor="#F0FFF2" borderRight="2px solid #0CA82B" borderRadius="0px 8px 8px 0px"
                    padding="8px 16px 8px 16px">
                    <CText font-family="Montserrat" font-size="25px" font-weight="700" line-height="30px"
                        color="#0CA82B">0%</CText>
                    <CText font-family="Montserrat" font-size="16px" font-weight="700" line-height="26px"
                        color="#0CA82B">-</CText>
                    <CText font-family="Montserrat" font-size="12px" font-weight="400" line-height="18px">
                        -
                    </CText>
                </CBox>
            </CGrid>
        </CBox>
        <!-- </CBox> -->
    </CBox>
</template>

<script>
import { CBox, CGrid, CText, CProgress, CImage } from '@chakra-ui/vue';
import config from "@/ConfigProvider";
import RadarChart from "./chart/Radar.vue";
import RadialChart from "./chart/Radial.vue";

export default {
    components: {
        CBox,
        CGrid,
        CText,
        CProgress,
        CImage,
        RadarChart,
        RadialChart
    },
    props: {
        result: {
            type: Object
        },
        type: {
            type: String
        },
        currentAppType: {
            type: Object
        }
    },
    data() {
        return {
            jobResult: Object.keys(this.result['conclusion']).length != 0 ? this.result['conclusion'] : this.sortItem(this.result['results'], "percentage", true)[0],
            selected: this.type === "Profession" ? this.sortItem(this.result['results'], "percentage", true)[0] : this.sortItem(this.result['competencies'], "scale", true)[0],
            shareUrl: config.value("DEVCODE_WEB_URL") + "/job/share/" + this.$route.params.id,
        }
    },
    mounted() { },
    computed: {
        shareText() {
            let text = "Hasil tes "
            if (this.type === "Profession") {
                text += "menunjukkan profesi saya cocok sebagai "
            }

            text += this.result["conclusion"][0].item
            return text
        },
        currentResult() {
            return this.jobResult
        }
    },
    methods: {
        changeSelected(key, color) {
            if (this.type === "Profession") {
                this.selected = this.result['results'].find(item => item.key === key)
                this.selected.color = color
            } else {
                this.selected = this.result['competencies'].find(item => item.key === key)
                this.selected.color = color
            }
        },
        setClipboard(value) {
            var tempInput = document.createElement("input");
            tempInput.style = "position: absolute; left: -1000px; top: -1000px";
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            this.$toast({
                title: "Copied",
                status: "success",
                duration: 3000,
                position: "top",
                isClosable: false,
            });
        },
        sortItem(arr, key, int = false) {
            if (int) {
                return arr.sort((a, b) => {
                    if (parseInt(b[key]) < parseInt(a[key])) return -1
                    if (parseInt(b[key]) > parseInt(a[key])) return 1
                    return 0
                })
            }
            return arr.sort((a, b) => b[key].localeCompare(a[key]))
        }
    }
}
</script>

<style>
.profession-container {
    width: 100%;
}
</style>
